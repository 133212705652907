.features23-layout349 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
}
.features23-max-width {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.features23-container1 {
  flex: 1;
  height: 100%;
  display: flex;
  align-self: flex-start;
  box-shadow: 2px 2px 4px 0px #d4d4d4;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-theme-accent1);
}
.features23-image {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.features23-text01 {
  text-align: left;
}
.features23-container2 {
  flex: 1;
  height: 100%;
  display: flex;
  align-self: flex-start;
  box-shadow: 2px 2px 4px 0px #d4d4d4;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  background-color: #e4d8c3;
}
.features23-image1 {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.features23-text03 {
  text-align: left;
}
.features23-container3 {
  flex: 1;
  height: 100%;
  display: flex;
  align-self: flex-start;
  box-shadow: 2px 2px 4px 0px #d4d4d4;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-theme-accent1);
}
.features23-image2 {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.features23-text05 {
  text-align: left;
}
.features23-container4 {
  flex: 1;
  height: 100%;
  display: flex;
  align-self: flex-start;
  box-shadow: 2px 2px 4px 0px #d4d4d4;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  background-color: #e4d8c3;
}
.features23-image3 {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.features23-text07 {
  text-align: left;
}
.features23-container5 {
  flex: 1;
  height: 100%;
  display: flex;
  align-self: flex-start;
  box-shadow: 2px 2px 4px 0px #d4d4d4;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-theme-accent1);
}
.features23-image4 {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.features23-text09 {
  text-align: left;
}
.features23-container6 {
  flex: 1;
  height: 100%;
  display: flex;
  align-self: flex-start;
  box-shadow: 2px 2px 4px 0px #d4d4d4;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  background-color: #e4d8c3;
}
.features23-image5 {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.features23-text11 {
  text-align: left;
}
@media(max-width: 991px) {
  .features23-max-width {
    flex-direction: column;
  }
  .features23-container1 {
    width: auto;
  }
}
@media(max-width: 767px) {
  .features23-container1 {
    width: 100%;
  }
  .features23-container2 {
    width: 100%;
  }
  .features23-container3 {
    width: 100%;
  }
  .features23-container4 {
    width: 100%;
  }
  .features23-container5 {
    width: 100%;
  }
  .features23-container6 {
    width: 100%;
  }
}
@media(max-width: 479px) {
  .features23-max-width {
    gap: var(--dl-space-space-oneandhalfunits);
  }
}

.hero9-header30 {
  gap: var(--dl-space-space-twounits);
  background-color: rgba(12, 12, 12, 1);
}
.hero9-container {
  top: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
  bottom: 0px;
  height: 506px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-theme-neutral-dark);
}
.hero9-ornament261 {
  top: 64px;
  left: -55px;
  width: 128px;
  height: 134px;
  position: absolute;
}
.hero9-max-width {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  z-index: 1;
  align-self: flex-end;
  align-items: center;
  flex-direction: column;
}
.hero9-content {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 1034px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.hero9-container1 {
  width: 100%;
  height: 210px;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-start;
}
.hero9-image {
  top: -32px;
  right: 53px;
  width: 438px;
  height: 400px;
  position: absolute;
  object-fit: cover;
}
.hero9-text {
  fill: rgb(250, 250, 250);
  color: rgb(250, 250, 250);
  font-size: 40px;
  align-self: flex-start;
  text-align: left;
  font-family: "Roboto Serif";
  font-weight: 400;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-left: var(--dl-space-space-threeunits);
  letter-spacing: 0em;
  text-transform: none;
  text-decoration: none;
}
.hero9-text03 {
  color: #64ccc5;
}
.hero9-actions {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-left: var(--dl-space-space-threeunits);
}
.hero9-button {
  border-color: 	rgb(0,128,128);
  text-decoration: none;
  background-color: 	rgb(0,128,128);
}
.hero9-text09 {
  text-align: center;
}
.hero9-button1 {
  fill: 	rgb(0,128,128);
  color: 	rgb(0,128,128);
  border-color: 	rgb(0,128,128);
  text-decoration: none;
}
.hero9-text10 {
  text-align: center;
}
.hero9-text11 {
  text-align: center;
}
@media(max-width: 1600px) {
  .hero9-image {
    top: -30px;
    right: -88px;
  }
  .hero9-text {
    padding-left: 200px;
  }
  .hero9-actions {
    padding-left: 200px;
  }
}
@media(max-width: 1200px) {
  .hero9-image {
    top: -35px;
    right: 51px;
  }
  .hero9-text {
    padding-left: var(--dl-space-space-fourunits);
  }
  .hero9-actions {
    padding-left: var(--dl-space-space-fourunits);
  }
}
@media(max-width: 991px) {
  .hero9-header30 {
    height: 457px;
  }
  .hero9-container {
    height: 460px;
  }
  .hero9-image {
    top: -2px;
    right: 208px;
    width: 340px;
    height: 289px;
  }
  .hero9-text11 {
    text-align: center;
  }
}
@media(max-width: 767px) {
  .hero9-container {
    height: 414px;
  }
  .hero9-content {
    width: 100%;
  }
  .hero9-container1 {
    width: 490px;
    flex-direction: row;
    justify-content: center;
  }
  .hero9-image {
    display: none;
  }
  .hero9-text {
    align-self: center;
    text-align: center;
    padding-left: 0px;
  }
  .hero9-actions {
    align-self: center;
    padding-left: 0px;
    justify-content: flex-start;
  }
}
@media(max-width: 479px) {
  .hero9-ornament261 {
    display: none;
  }
  .hero9-text {
    color: rgb(250, 250, 250);
    width: 425px;
    font-size: 30px;
    text-align: center;
    padding-left: 0px;
  }
  .hero9-text03 {
    color: #64ccc5;
  }
  .hero9-actions {
    width: 100%;
    padding-left: 0px;
    flex-direction: column;
  }
  .hero9-button {
    width: 100%;
  }
  .hero9-button1 {
    width: 100%;
  }
}

.footer1-footer1 {
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
}
.footer1-max-width {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.footer1-content {
  gap: var(--dl-space-space-fourunits);
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: var(--dl-radius-radius-radius4);
}
.footer1-newsletter {
  gap: 24px;
  width: 500px;
  display: flex;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.footer1-image {
  width: 200px;
  object-fit: cover;
}
.footer1-content1 {
  fill: var(--dl-color-theme-neutral-light);
  color: var(--dl-color-theme-neutral-light);
}
.footer1-actions {
  gap: 16px;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.footer1-content2 {
  fill: var(--dl-color-theme-neutral-light);
  color: var(--dl-color-theme-neutral-light);
  height: auto;
  font-size: 12px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.footer1-links {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  justify-content: flex-end;
}
.footer1-column1 {
  gap: var(--dl-space-space-unit);
  width: auto;
  display: flex;
  overflow: hidden;
  flex-grow: 1;
  max-width: 300px;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.footer1-column1-title {
  fill: var(--dl-color-theme-neutral-light);
  color: var(--dl-color-theme-neutral-light);
}
.footer1-footer-links {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.footer1-link1 {
  fill: var(--dl-color-theme-neutral-light);
  color: var(--dl-color-theme-neutral-light);
  text-decoration: none;
}
.footer1-link2 {
  fill: var(--dl-color-theme-neutral-light);
  color: var(--dl-color-theme-neutral-light);
  text-decoration: none;
}
.footer1-link4 {
  fill: var(--dl-color-theme-neutral-light);
  color: var(--dl-color-theme-neutral-light);
  text-decoration: none;
}
.footer1-column3 {
  gap: var(--dl-space-space-unit);
  width: auto;
  display: flex;
  overflow: hidden;
  flex-grow: 1;
  max-width: 300px;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.footer1-social-link1-title {
  fill: var(--dl-color-theme-neutral-light);
  color: var(--dl-color-theme-neutral-light);
}
.footer1-social-links {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.footer1-link {
  gap: 12px;
  display: flex;
  padding: 8px 0;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.footer1-icon {
  fill: #D9D9D9;
}
.footer1-social-link4 {
  fill: var(--dl-color-theme-neutral-light);
  color: var(--dl-color-theme-neutral-light);
  text-decoration: none;
}
.footer1-link1 {
  gap: 12px;
  display: flex;
  padding: 8px 0;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.footer1-icon2 {
  fill: #D9D9D9;
}
.footer1-social-link5 {
  fill: var(--dl-color-theme-neutral-light);
  color: var(--dl-color-theme-neutral-light);
  text-decoration: none;
}
.footer1-root-class-name {
  background-color: var(--dl-color-theme-neutral-dark);
}
@media(max-width: 991px) {
  .footer1-newsletter {
    width: 300px;
  }
}
@media(max-width: 767px) {
  .footer1-content {
    flex-direction: column;
  }
  .footer1-newsletter {
    width: 100%;
  }
  .footer1-links {
    width: 100%;
    align-items: flex-start;
    justify-content: center;
  }
  .footer1-column1 {
    align-items: center;
  }
  .footer1-column1-title {
    text-align: center;
  }
  .footer1-footer-links {
    align-self: center;
  }
  .footer1-column3 {
    align-items: center;
  }
  .footer1-social-link1-title {
    text-align: center;
  }
  .footer1-social-links {
    align-self: center;
  }
}
@media(max-width: 479px) {
  .footer1-actions {
    width: 100%;
  }
  .footer1-links {
    flex-direction: column;
  }
  .footer1-column1 {
    width: 100%;
    max-width: 100%;
    align-items: center;
    justify-content: center;
  }
  .footer1-footer-links {
    align-items: center;
    justify-content: center;
  }
  .footer1-column3 {
    width: 100%;
    max-width: 100%;
    align-items: center;
    justify-content: center;
  }
  .footer1-social-links {
    align-items: center;
    justify-content: center;
  }
}

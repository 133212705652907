.contact-form11-contact11 {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-direction: column;
}
.contact-form11-container {
  width: 636px;
  height: 766px;
  align-self: center;
}
.contact-form11-container1 {
  display: contents;
}
@media(max-width: 479px) {
  .contact-form11-container {
    width: 100%;
  }
}

.navbar4-container {
  width: 100%;
  display: flex;
  z-index: 100;
  justify-content: center;
  background-color: var(--dl-color-theme-neutral-dark);
}
.navbar4-navbar-interactive {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
  background-color: var(--dl-color-theme-neutral-dark);
}
.navbar4-image {
  width: 200px;
  object-fit: cover;
}
.navbar4-desktop-menu {
  flex: 1;
  display: flex;
  justify-content: space-between;
}
.navbar4-links {
  gap: var(--dl-space-space-twounits);
  flex: 1;
  display: flex;
  align-items: center;
  margin-left: var(--dl-space-space-twounits);
  flex-direction: row;
  justify-content: flex-end;
}
.navbar4-link1 {
  fill: var(--dl-color-theme-secondary1);
  color: var(--dl-color-theme-secondary1);
  font-style: normal;
  text-decoration: none;
}
.navbar4-link2 {
  fill: var(--dl-color-theme-secondary1);
  color: var(--dl-color-theme-secondary1);
  font-size: 20px;
  font-family: "DM Sans";
  font-weight: 400;
  text-transform: none;
  text-decoration: none;
}
.navbar4-link4 {
  fill: var(--dl-color-theme-secondary1);
  color: var(--dl-color-theme-secondary1);
  text-decoration: none;
}
.navbar4-buttons {
  gap: var(--dl-space-space-twounits);
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: center;
  margin-left: var(--dl-space-space-twounits);
}
.navbar4-burger-menu {
  display: none;
}
.navbar4-icon {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.navbar4-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: var(--dl-space-space-twounits);
  z-index: 100;
  position: absolute;
  flex-direction: column;
  background-color: var(--dl-color-theme-neutral-light);
}
.navbar4-nav {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.navbar4-top {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.navbar4-logo {
  height: 3rem;
}
.navbar4-close-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}
.navbar4-icon2 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.navbar4-links1 {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.navbar4-buttons1 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: center;
}
.navbar4-root-class-name {
  top: 0px;
  left: 0px;
  position: static;
  align-self: center;
  background-color: var(--dl-color-theme-neutral-dark);
}
@media(max-width: 1600px) {
  .navbar4-container {
    width: 100%;
    z-index: 100;
    justify-content: center;
  }
  .navbar4-root-class-name {
    width: 100%;
    align-self: center;
  }
}
@media(max-width: 991px) {
  .navbar4-navbar-interactive {
    justify-content: space-between;
    background-color: rgba(12, 12, 12, 1);
  }
  .navbar4-links {
    align-items: center;
    flex-direction: row;
    justify-content: flex-end;
  }
  .navbar4-root-class-name {
    background-color: rgba(12, 12, 12, 1);
  }
}
@media(max-width: 767px) {
  .navbar4-navbar-interactive {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    justify-content: center;
  }
  .navbar4-desktop-menu {
    display: none;
  }
  .navbar4-burger-menu {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media(max-width: 479px) {
  .navbar4-navbar-interactive {
    padding: var(--dl-space-space-unit);
  }
  .navbar4-mobile-menu {
    padding: var(--dl-space-space-unit);
  }
}

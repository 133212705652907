.features1-layout251 {
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
.features1-container {
  flex: 0 0 auto;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.features1-column {
  gap: var(--dl-space-space-halfunit);
  align-items: center;
  flex-shrink: 0;
}
.features1-text {
  fill: #64CCC5;
  color: #64CCC5;
}
.features1-text1 {
  width: 525px;
  font-size: 45px;
  text-align: center;
  font-family: "Roboto Serif";
  font-weight: 400;
  letter-spacing: 0em;
  text-transform: none;
  text-decoration: none;
}
@media(max-width: 767px) {
  .features1-column {
    width: 100%;
  }
}
@media(max-width: 479px) {
  .features1-text1 {
    width: 371px;
    height: 71px;
    font-size: 30px;
  }
}
